import React, { useState, useEffect } from "react";
import { Grid, TextField, CircularProgress, Button, Paper, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { SaveAlt, WhatsApp, Facebook, Instagram } from "@material-ui/icons";
import * as XLSX from 'xlsx';
import api from "../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom"; // Import necessário para redirecionamento

// Estilos com Material UI
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: theme.palette.fancyBackground,
    padding: theme.spacing(3),
  },
  filterContainer: {
    padding: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    overflow: "auto",
    height: "60vh",
  },
  exportButton: {
    marginLeft: theme.spacing(2),
  },
  formControlSelect: {
    minWidth: 120,
    maxWidth: 180,
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
}));

const Reports3 = () => {
  const classes = useStyles();
  const history = useHistory();
  const [allData, setAllData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [filters, setFilters] = useState({
    numeroContato: "",
    cliente: "",
    statusSistema: "",
    dateFrom: moment().startOf('month').format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // Função para buscar contatos
  const fetchContacts = async (query = "") => {
    try {
      setLoading(true);
      const { data } = await api.get("/contacts", {
        params: { searchParam: query },
      });
      setOptions(data.contacts || []);
    } catch (error) {
      toast.error("Erro ao buscar os contatos.");
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar clientes (opções do select)
  const fetchClientes = async () => {
    try {
      const response = await api.get("/clientes");
      console.log(response.data); // Log para verificar os dados recebidos
      setClientes(response.data);
    } catch (error) {
      toast.error("Erro ao buscar clientes.");
    }
  };


  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get("/relatorio_003", {
        params: { ...filters },
      });

      if (response.data && Array.isArray(response.data)) {
        setAllData(response.data);
        setTotalRecords(response.data.length);
        paginateData(response.data, pageNumber, pageSize);
      } else {
        setAllData([]);
        setDataToDisplay([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);

      if (error.response && error.response.data.error === "ERR_SESSION_EXPIRED") {
        toast.error("Sessão expirada. Por favor, faça login novamente.");
        history.push("/login");
      } else {
        toast.error("Erro ao buscar dados.");
      }
    } finally {
      setLoading(false);
    }
  };

  const paginateData = (data, page, size) => {
    const startIndex = (page - 1) * size;
    const paginatedData = data.slice(startIndex, startIndex + size);
    setDataToDisplay(paginatedData);
  };

  useEffect(() => {
    fetchContacts();
    fetchClientes(); // Chama a função para buscar os clientes ao carregar o componente
  }, []);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    paginateData(allData, pageNumber, pageSize);
  }, [pageNumber, pageSize, allData]);

  const exportToExcel = () => {
    const formattedData = allData.map(item => ({
      ...item,
      "Inicio Atendimento": item["Inicio Atendimento"] ? moment(item["Inicio Atendimento"]).format("YYYY-MM-DD HH:mm:ss") : '',
      "Fim do Atendimento": item["Fim do Atendimento"] ? moment(item["Fim do Atendimento"]).format("YYYY-MM-DD HH:mm:ss") : '',
      "Entrou no sistema": item["Entrou no sistema"] ? moment(item["Entrou no sistema"]).format("YYYY-MM-DD HH:mm:ss") : '',
      "Ultima atualizacao": item["Ultima atualizacao"] ? moment(item["Ultima atualizacao"]).format("YYYY-MM-DD HH:mm:ss") : ''
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Relatorio_003");

    const currentDateTime = moment().format("YYYYMMDDHHmm");
    const fileName = `relatorio_operacional_${currentDateTime}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const renderContactAutocomplete = () => (
    <Grid xs={12} item>
      <Autocomplete
        fullWidth
        options={options}
        loading={loading}
        getOptionLabel={(option) => `${option.name} - ${option.number || ""}`}
        onOpen={() => {
          if (options.length === 0) fetchContacts();
        }}
        onChange={(event, newValue) => {
          setFilters({ ...filters, numeroContato: newValue ? newValue.number : "" });
        }}
        renderOption={(option) => (
          <>
            {option.channel === "whatsapp" && <WhatsApp style={{ color: "#25D366", marginRight: 8 }} />}
            {option.channel === "facebook" && <Facebook style={{ color: "#3b5998", marginRight: 8 }} />}
            {option.channel === "instagram" && <Instagram style={{ color: "#E1306C", marginRight: 8 }} />}
            <Typography>{`${option.name} - ${option.number || ""}`}</Typography>
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Digite para pesquisar o contato"
            variant="outlined"
            fullWidth
            onChange={(e) => setSearchParam(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Grid>
  );

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPageNumber(1);
  };

  const getDisplayedRecordsRange = () => {
    const startRecord = (pageNumber - 1) * pageSize + 1;
    const endRecord = Math.min(pageNumber * pageSize, totalRecords);
    return `${startRecord}-${endRecord}`;
  };

  return (
    <div className={classes.mainContainer}>
      <h1>Relatório Operacional</h1>
      <Paper className={classes.filterContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} xl={3}>
            {renderContactAutocomplete()}
          </Grid>
<Grid item xs={12} md={3} xl={3}>
  {/* Transformar Cliente em select */}
  <FormControl variant="outlined" fullWidth>
    <InputLabel>Cliente</InputLabel>
    <Select
      value={filters.cliente}
      onChange={(e) => setFilters({ ...filters, cliente: e.target.value })}
      label="Cliente"
    >
      <MenuItem value="">
        <em>Todos</em>
      </MenuItem>
      {clientes.map((cliente, index) => (
        <MenuItem key={index} value={cliente["Cliente"]}>
          {cliente["Cliente"]}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

          <Grid item xs={12} md={3} xl={3}>
            <TextField
              label="Status do Sistema"
              name="statusSistema"
              value={filters.statusSistema}
              onChange={e => setFilters({ ...filters, statusSistema: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <TextField
              label="Data Inicial"
              type="date"
              name="dateFrom"
              value={filters.dateFrom}
              onChange={e => setFilters({ ...filters, dateFrom: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <TextField
              label="Data Final"
              type="date"
              name="dateTo"
              value={filters.dateTo}
              onChange={e => setFilters({ ...filters, dateTo: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3} style={{ textAlign: "right" }}>
            <Button variant="contained" color="primary" onClick={fetchData} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : "Aplicar Filtro"}
            </Button>
            <Tooltip title="Exportar para Excel">
              <IconButton className={classes.exportButton} onClick={exportToExcel}>
                <SaveAlt />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Conta</TableCell>
              <TableCell>ID Ticket</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Rank</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToDisplay.length > 0 ? (
              dataToDisplay.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.Conta}</TableCell>
                  <TableCell>{row["ID Ticket"]}</TableCell>
                  <TableCell>{row.Cliente}</TableCell>
                  <TableCell>{row.rank ? row.rank : "Sem rank"}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>Nenhum dado encontrado</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      <div className={classes.paginationContainer}>
        <Typography>
          Total de registros: {getDisplayedRecordsRange()} de {totalRecords}
        </Typography>
        <FormControl variant="outlined" size="small" className={classes.formControlSelect}>
          <InputLabel>Por página</InputLabel>
          <Select value={pageSize} onChange={handlePageSizeChange} label="Por página">
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={Math.ceil(totalRecords / pageSize)}
          page={pageNumber}
          onChange={(event, value) => setPageNumber(value)}
        />
      </div>
    </div>
  );
};

export default Reports3;
